import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorPatryk as author } from 'data/authors';
import Link from 'components/ui/link';
import img from 'img/blog/covers/zendesk.png';
import img1 from 'img/ui/integration.png';

const Content = () => {
  return (
    <Post>
      <p>
        We have launched our new Zendesk integration! You can install it straight from the{' '}
        <Link
          href="https://www.zendesk.com/apps/support/livesession/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zendesk Marketplace
        </Link>
        . It adds links to your identified user’s latest sessions in the ticket view:
      </p>
      <img
        src={img1}
        alt="Zendesk integration"
        title="Add more context to every support ticket you get"
      />
      <p>
        {' '}
        To use LiveSession’s Zendesk integration, you will need to{' '}
        <Link
          href="https://app.livesession.io/app/settings/api-tokens"
          target="_blank"
          rel="noopener noreferrer"
        >
          create a personal access token
        </Link>{' '}
        and provide it on the integration’s settings page on Zendesk.com.
      </p>

      <p>
        To ensure the integration will work as expected, remember to{' '}
        <Link
          href="/help/user-identification-custom-properties/"
          target="_blank"
          rel="noopener noreferrer"
        >
          set up user identification
        </Link>{' '}
        and provide the user’s email address.{' '}
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'New Zendesk integration',
  url: '/blog/zendesk/',
  description: `We have updated our Zendesk integration. Now you can install it directly from the Zendesk Marketplace. It will add links to your identified user's sessions.`,
  author,
  img,
  imgSocial: img,
  date: '2021-07-15',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
